import React from "react"
import { graphql } from "gatsby"
import SEO from "../SEO"
import Image from "../components/image"
import Layout from "../components/layout"
import GA from "../components/ga"
// import SidebarCart from "../components/sidebarCart"

import styles from "./scss/404.module.scss"

export default (props) => {
  return (
    <>
      <SEO title="Brophy Brothers | 404" />
      <GA />
      <Layout>
        {/* <SidebarCart /> */}
        <section className={`section ${styles.pageNotFound}`}>
          <p>{props.data.contentfulPageNotFound.text}</p>

          <Image image={props.data.contentfulPageNotFound.image} />

          {typeof window !== "undefined" && (
            <button
              className={styles.goBack}
              onClick={() => window.history.back()}
            >
              {props.data.contentfulPageNotFound.buttonText}
            </button>
          )}
        </section>
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    contentfulPageNotFound {
      text
      image {
        title
        description
        fluid(quality: 100) {
          src
          srcSet
          srcWebp
          srcSetWebp
        }
      }
      buttonText
    }
  }
`
